<template>
  <v-container>
    <AdminUsersPermissionsFacilitiesVue :userId="userId" />
  </v-container>
</template>

<script>
import AdminUsersPermissionsFacilitiesVue from "./AdminUsersPermissionsFacilities.vue";

export default {
  name: "AdminUsersPermissions",
  components: {
    AdminUsersPermissionsFacilitiesVue,
  },
  props: {
    userId: {
      default: null,
    },
  },
};
</script>
