import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { createPaginatedAction } from "../utils/feathers-paginated";

export class UserPermission extends BaseModel {
  static instanceDefaults(data = null) {
    if (data?.rule_type) {
      // Only allow a certain set of strings
      data.rule_type = data.rule_type.toLowerCase();
      if (!["role", "permission", "custom"].includes(data.rule_type)) {
        throw new Error(`Invalid rule_type: ${data.rule_type}`);
      }
    }
    return {
      id: data?.id ?? null,
      title: data?.title ?? "",
      rule_type: data?.rule_type ?? null,
      user_id: data?.user_id ?? null,
      subject: data?.subject ?? null,
      value: data?.value ?? null,
      additional_data: data?.additional_data ?? null,
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }
}

const servicePath = "user-permissions";
const useUserPermissions = defineStore({
  idField: "id",
  clients: { api },
  Model: UserPermission,
  servicePath,
  getters: {
    facilityEditorParams() {
      return {
        rule_type: "role",
        title: "Editor",
        subject: "facility",
      };
    },
  },
  actions: {
    addFacilityEditor(userId, facilityId) {
      const userPermission = new this.Model({
        ...this.facilityEditorParams,
        value: facilityId,
        user_id: userId,
      });
      // Save it to the database
      return userPermission.save();
    },
    getFacilityEditors: createPaginatedAction({
      query: {
        title: "Editor",
        subject: "facility",
      },
    }),
  },
});

api.service(servicePath).hooks({});

export { useUserPermissions };
