<template>
  <v-card>
    <v-card-title>
      Facility Edit Access
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="addFacilityMode = !addFacilityMode"
          >
            <v-icon :color="addFacilityMode ? 'red' : ''">
              {{ addFacilityMode ? "mdi-close" : "mdi-plus" }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ addFacilityMode ? "Cancel" : "Add Facility" }}</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-container>
      <Transition name="slide-fade">
        <v-list two-line v-if="!addFacilityMode">
          <v-list-item v-if="items.length === 0">
            <v-list-item-title> No edit access </v-list-item-title>
          </v-list-item>
          <v-list-item v-else v-for="item in items" :key="item.id">
            <v-list-item-content>
              <v-list-item-title
                v-text="facilitiesStore.getFacilityCode(item.value) || '???'"
              >
              </v-list-item-title>

              <v-list-item-subtitle
                v-text="facilitiesStore.getFacilityName(item.value) || ''"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="red"
                    @click="removeRule(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove Edit Access</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-list v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-select
                  v-model="addFacilityId"
                  label="Facility"
                  :items="filteredFacilities"
                  item-text="title"
                  item-value="id"
                  clearable
                ></v-select>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="addFacilityId === null"
                    :color="addFacilityId === null ? 'grey' : 'primary'"
                    @click="addFacility"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Edit Access</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </Transition>
    </v-container>
  </v-card>
</template>

<script>
import { computed, ref } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useFacilities } from "../store/facilities.pinia";
import { useUserPermissions } from "../store/userPermissions.pinia";

export default {
  name: "AdminUsersPermissionsFacilities",
  props: {
    userId: {
      default: null,
    },
  },
  setup(props) {
    const facilitiesStore = useFacilities();
    const userPermissionsStore = useUserPermissions();
    const addFacilityMode = ref(false);
    const addFacilityId = ref(null);

    const { items, isPending } = useFind({
      model: userPermissionsStore.Model,
      params: {
        query: {
          user_id: props.userId,
          ...userPermissionsStore.facilityEditorParams,
        },
      },
    });

    const filteredFacilities = computed(() => {
      return (facilitiesStore.activeFacilities ?? []).filter((facility) => {
        // If there are no items, nothing to filter
        if (!items?.value?.length) return true;
        // Don't include facilities the user already has access to
        if (items?.value?.find((item) => item.value === facility.id))
          return false;
        return true;
      });
    });

    const addFacility = () => {
      userPermissionsStore
        .addFacilityEditor(props.userId, addFacilityId.value)
        .then(() => {
          addFacilityId.value = null;
          addFacilityMode.value = false;
        });
    };

    const removeRule = (ruleId) => {
      userPermissionsStore.remove(ruleId);
    };

    return {
      facilitiesStore,
      filteredFacilities,
      items,
      isPending,
      addFacilityMode,
      addFacility,
      addFacilityId,
      removeRule,
    };
  },
};
</script>
