var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Facility Edit Access "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.addFacilityMode = !_vm.addFacilityMode}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.addFacilityMode ? 'red' : ''}},[_vm._v(" "+_vm._s(_vm.addFacilityMode ? "mdi-close" : "mdi-plus")+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.addFacilityMode ? "Cancel" : "Add Facility"))])])],1),_c('v-divider'),_c('v-container',[_c('Transition',{attrs:{"name":"slide-fade"}},[(!_vm.addFacilityMode)?_c('v-list',{attrs:{"two-line":""}},[(_vm.items.length === 0)?_c('v-list-item',[_c('v-list-item-title',[_vm._v(" No edit access ")])],1):_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.facilitiesStore.getFacilityCode(item.value) || '???')}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.facilitiesStore.getFacilityName(item.value) || '')}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.removeRule(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove Edit Access")])])],1)],1)})],2):_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-select',{attrs:{"label":"Facility","items":_vm.filteredFacilities,"item-text":"title","item-value":"id","clearable":""},model:{value:(_vm.addFacilityId),callback:function ($$v) {_vm.addFacilityId=$$v},expression:"addFacilityId"}})],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.addFacilityId === null,"color":_vm.addFacilityId === null ? 'grey' : 'primary'},on:{"click":_vm.addFacility}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add Edit Access")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }